
import React from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()

  return (
    <ChatComponent
      name="Demo: Fahrerlaubnis"
      pageTitle="Demo: Fahrerlaubnis"
      // homepage="#"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      // directline
      // token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s"
      // courier (dev + local)
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODQyMzc0ODQuNzU3NzU3LCJleHAiOjIzMTQ5NTc0ODQuNzU3NzU3LCJib3RJZCI6IjIwODNhMWF4M2xmbDd4ZmQzIiwiaXNzIjoiY29udmFpc2UiLCJhdWQiOiJjb252YWlzZS1jb3VyaWVyIn0.FvSOlt6NiBwnsE0PGcKJaaRdhBa6KdP8fqpFy9Dq63a4pQIfgcz1Ehfmk89Y0halJs19vYSvz6XaleYzO0wX28qQWyL9c1Xcn6reENJyTJKx48HoWcpzG6NHkerpS3DAL3vxUEMDl9RyljPZmXIAjezbld3btI1cTVbs1bKWMv-hHho2stYkF-jX17xC7yyGLdq7fKyJrEW-XkReCJ4EOmC_BkbaC08vNf2TTDhIh8NDFfQWNOiHAO8dRu9rRYDwNzFlMX2n9LX8zmHyX8rYkjCzpVNWQ8bEVzt5s8ufRKmoyoheyKKocUXDIsMYKjixA-yHiESmXwgZ3rfEGX3G1B_pzk2Y1FwKBOhqdbfAwMn8816sSPh9b0jTVrPJM8kLoMOJuJAVxeQGinob38afKNGWQbJKJjgzOOeOWRRfTlM6IeDFOcAvxtzRIlYlikYs1Rlpe9nc8VnuMObnhKd5Rqjm7xBMeqhphXT8-oX58UZOfgG1mmfO-21Rag_voVHNQEJG0OU_MU1DzwiTHxt470zhUKqkkNV3sTThlQcYp6fTCOAaRMXVlDH6tCjIbBiSol7ksNuJTJOuGeDtrfdvM6Xts4YBquw01-zTUuUZYUxrZjuMa-kEVjoP8uQfL2rN0QBv-5-mwKzu5QkTISeDTE6oRpQuJzBfiTu962kDJlM"  
      botId="2083a1ax3lfl7xfd3"
      avatar={false}
      avatarInitials={false}
      logo="https://static.convaise.com/convaise-logo.svg"
      // domain="https://europe.directline.botframework.com/v3/directline"
      // domain="http://localhost:8000/v3/directline"
      domain="https://courier.convaise.com/frontend/v3/directline"
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#394d75",
        botAvatarBackgroundColor: "#fff",
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      // styleCustomizations={{
      //   "--button-blue": "#551285",
      //   "--button-blue-light": "#77419D",
      //   "--button-orange": "rgba(255, 166, 0, 1)",
      //   "--button-orange-light": "rgb(255, 182, 46)",
      // }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
